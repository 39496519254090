import * as React from 'react';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';

const marks = [
  {
    value: 800,
    label: '0800hrs',
  },
  {
    value: 1250,
    label: '1230hrs'
  },
  {
    value: 1700,
    label: '1700hrs',
  },
];

function valueLabelFormat(value: any) {
  if (value % 100 == 0) {

    if (value < 1000) {
      return `0${value}hrs`
    }
    return `${value}hrs`

  } else {

    if (value < 1000) {
      return `0${Math.floor(value/100)}30hrs`
    }

    return `${Math.floor(value/100)}30hrs`
  }
}

interface TimePeriodSelectorProperties {
  id: number,
  lower_bound: number,
  upper_bound: number,
  start_time_default: number,
  end_time_default: number,
  onChange: Function
}

function TimePeriodSelector(props: TimePeriodSelectorProperties) {

  const [value, setValue] = React.useState([props.start_time_default, props.end_time_default]);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    props.onChange(event, newValue, props.id);
  };

  return (
    <Slider
      valueLabelFormat={valueLabelFormat}
      value={value}
      onChange={handleChange}
      step={50}
      min={props.lower_bound}
      max={props.upper_bound}
      valueLabelDisplay="auto"
      marks={marks}
    />
  )
}

interface PartialDaySelectorProperties {
  lower_bound: number,
  upper_bound: number,
  start_time_default: number,
  end_time_default: number,
  onChange: Function
}

function PartialDaySelector(props: PartialDaySelectorProperties){

  const onChange = (event: any, newValue: any, id: number) => {
    var new_array : number[][] = periods;
    new_array[id] = newValue;
    setPeriods(new_array)
    props.onChange(periods)
  };

  const [selectors, setSelectors] = React.useState<JSX.Element[]>([
    <TimePeriodSelector key={0} id={0} lower_bound={props.lower_bound} upper_bound={props.upper_bound} 
    start_time_default={props.start_time_default} end_time_default={props.end_time_default} onChange={onChange} />])

  const [periods, setPeriods] = React.useState<number[][]>([[]]);

  const addSelector = () => {
    setSelectors([...selectors, <TimePeriodSelector key={selectors.length} id={1} lower_bound={props.lower_bound} upper_bound={props.upper_bound} 
      start_time_default={props.start_time_default} end_time_default={props.end_time_default} onChange={onChange} />])
  }

  return (
    <Grid item xs={12}>
      <FormLabel component="legend">What periods of time are you available?</FormLabel>
        <Box padding={2}>
          {selectors}
          {selectors.length < 3 ? 
            <Button variant="outlined" startIcon={<AddIcon />} onClick={addSelector}>
              Add time period
            </Button> 
          : null }
      </Box>
    </Grid>
  );
}

export default function AvailabilityForm() {

  const [value, setValue] = React.useState("all");
  const [timePeriods, setTimePeriods] = React.useState<number[][]>([[]]);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const onTimePeriodsChange = (newValue: number[][]) => {
    setTimePeriods(newValue);
  }

  return (
    <React.Fragment>
      <Grid container spacing={3} paddingX={1}>
        <Grid item xs={12}>
          <FormLabel component="legend">What is your availability for the above time period?</FormLabel>
          <RadioGroup
            aria-label="general-availability"
            value={value}
            onChange={handleChange}
            name="radio-buttons-group"
          >
            <FormControlLabel value="all" control={<Radio />} label="All of it" />
            <FormControlLabel value="none" control={<Radio />} label="None of it" />
            <FormControlLabel value="some" control={<Radio />} label="Some of it" />
          </RadioGroup>
        </Grid>
        { value == "some" ? 
          <PartialDaySelector lower_bound={800} upper_bound={1800} 
            start_time_default={900} end_time_default={1300} onChange={onTimePeriodsChange} /> 
        : null}
      </Grid>
    </React.Fragment>
  );
}