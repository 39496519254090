import React, { useState } from 'react';
import { Typography, TextField, Button } from '@mui/material';
import Box from '@mui/system/Box';

import { useFormik } from 'formik';

import { Auth } from 'aws-amplify';

import * as Yup from 'yup';

export default function LoginForm(props: any) {

    const [error, setError] = useState<string>('')

    async function signIn(username: string, password: string) {
        try {
            let user = await Auth.signIn(username, password);
            
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                props.onSubmit(user)
            }
            else {
                props.onSubmit(user)
            }

        } catch (error: any) {
            if (error.code == 'UserNotFoundException' || error.code == 'NotAuthorizedException') {
                setError('Incorrect username or password.')
            }
            else {
                console.log(error)
            }
        }
    }

    const loginForm = useFormik({
        initialValues:{
            username: '',
            password: ''
        },
        validationSchema: Yup.object({
            username: Yup.string()
                .required('Required'),
            password: Yup.string()
                .required('Required'),
        }),
        onSubmit: (values, {setSubmitting}) => {
            signIn(
                values['username'],
                values['password']
            )
        }
    })

    return (
        <React.Fragment>
            <Typography component="h1" variant="h4" align="center" marginBottom={2}>
                Login
            </Typography>
            <Box sx={{ '& > :not(style)': { my: 1 }}}>
                <form onSubmit={loginForm.handleSubmit}>
                    <Typography mb={2} color='red'>{error != null ? error : null}</Typography>
                    <TextField 
                        sx={{mb:2}} 
                        id="username" 
                        label="Username" 
                        variant="outlined" 
                        fullWidth={true} 
                        value={loginForm.values.username}
                        onChange={loginForm.handleChange}
                        error={loginForm.touched.username && Boolean(loginForm.errors.username)}
                        helperText={loginForm.touched.username && loginForm.errors.username}
                    />
                    <TextField 
                        id="password" 
                        label="Password" 
                        variant="outlined" 
                        type="password" 
                        fullWidth={true}
                        value={loginForm.values.password}
                        onChange={loginForm.handleChange}
                        error={loginForm.touched.password && Boolean(loginForm.errors.password)}
                        helperText={loginForm.touched.password && loginForm.errors.password}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            type="submit"
                            size="large"
                            variant="contained"
                            sx={{ mt: 2, ml: 1 }}
                        >
                            Login
                        </Button>
                    </Box>
                </form>
            </Box>
        </React.Fragment>
    );
}