import React, { useState, useLayoutEffect } from 'react';
import { Box, Container, IconButton, ImageList, ImageListItem, Typography } from '@mui/material';
import { Theme, useTheme } from '@mui/system';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

import l3s from '../static/images/l3s.jpg'
import m3s from '../static/images/m3s.jpg'
import m1s from '../static/images/m1s.jpg'
import l1s from '../static/images/l1s.jpg'


function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}

function QuiltedImageList() {

  const theme = useTheme<Theme>()
  const [rowHeight, setRowHeight] = useState(400)

  useLayoutEffect(() => {
    const width = window.innerWidth;

    if (width < 600) {
      setRowHeight(150)
    } else if (width < 900) {
      setRowHeight(200)
    }
    else {
      setRowHeight(300)
    }
  });

  return (
    <ImageList
      sx={{ width: '100%', marginTop: 0 }}
      variant="quilted"
      cols={4}
      rowHeight={rowHeight}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
          <img
            {...srcset(item.img, 500, item.rows, item.cols)}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: l3s,
    rows: 2,
    cols: 2,
  },
  {
    img: m3s
  },
  {
    img: m1s
  },
  {
    img: l1s,
    cols: 2,
  }
];

export default function HomePage() {

  return (
    <Container component="main" maxWidth="xl" sx={{ px: 0 }}>
      <QuiltedImageList />
      <Box sx={{p:2}}>
        <Typography variant="h2" sx={{mb:1}}>About Us</Typography>
        <Typography sx={{mb:2}}>Newcastle Medics Hockey Club is a (field) hockey club based in Newcastle in the North of England.</Typography>
        <Typography sx={{mb:2}}>There are no set standards for entry. Medics, non-medics, experienced players and newcomers to the game are all welcome. We don't take ourselves too seriously and you can commit yourself to as much or as little hockey as you like.</Typography>
        <Typography sx={{mb:1}}>Message us on one of our socials to give us a try.</Typography>
        <IconButton aria-label="facebook" size="large" href="https://www.facebook.com/medicshockey/" color="primary">
          <FacebookIcon fontSize="inherit" />
        </IconButton>
        <IconButton aria-label="instagram" size="large" href="https://www.instagram.com/medicshockey/" color="primary">
          <InstagramIcon fontSize="inherit" />
        </IconButton>
      </Box>
    </Container>
  );
}