import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';

import LoginForm from '../forms/auth/login'
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import FinishAccountSetupForm from '../forms/auth/finishAccountSetup';
import { Container } from '@mui/material';


export default function LoginPage(){

    const handleSubmit: Function = (user: any) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            setForm(<FinishAccountSetupForm user={user} />)
        } else {
            navigate('/')
        }
    }

    const [form, setForm] = useState(<LoginForm onSubmit={handleSubmit} />)
    const [user, setUser] = useState()

    let navigate = useNavigate();

    useEffect(() => {

        if (user == null) {

            Auth.currentUserPoolUser().then(user => {
            
                setUser(user)
    
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    setForm(<FinishAccountSetupForm user={user} />);
                }
            }).catch(error => {
    
            });
        }
        
    });

    return (
        <Container component="main" maxWidth="xs" sx={{sm: {px:0}}}>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                {form}
            </Paper>
        </Container>
    );
}