import React, { useEffect, useState } from 'react';
import './App.scss';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import AvailabilityMainPage from './pages/availability';
import LoginPage from './pages/login';
import HomePage from './pages/home'

import ResponsiveAppBar from './components/appbar';
import { Routes, Route, Navigate, useLocation, Link } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

import useAuth from './helpers/userHelper';
import { Alert, AlertTitle, Container } from '@mui/material';

Amplify.configure(awsconfig);


const theme = createTheme({
  palette: {
    primary: {
      main: '#77102b'
    }
  }
});

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      {/* <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '} */}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function App() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ResponsiveAppBar />
      {/* <AppBar
        position="absolute"
        color="primary"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Newcastle Medics
          </Typography>
        </Toolbar>
      </AppBar> */}

      <Routes>
        <Route path="" element={<HomePage />} />
        <Route path="/availability" element=
        {
        <RequireAuth>
          <AvailabilityMainPage />
        </RequireAuth>
        } />
        <Route path="/login" element={<LoginPage />} />
      </Routes>
      {/* <Authenticator>
          {({ signOut, user }) => (
            <main>
              <h1>Hello {user.username}</h1>
              <button onClick={signOut}>Sign out</button>
            </main>
          )}
        </Authenticator> */}
      <Copyright />
    </ThemeProvider>
  )
}

let notLoggedInPage = NotLoggedIn()

function RequireAuth({ children }: { children: JSX.Element }) {

  const [returned, setReturned] = useState<JSX.Element>(<></>);
  let auth = useAuth();
  let location = useLocation();
  
  useEffect(() => {

    if (auth.currentUser == null) {
      // setReturned(<Navigate to="/login" state={{ from: location }} replace />)
      setReturned(notLoggedInPage)
      return;
    }

    setReturned(children);
  });

  return returned;
  
}

function NotLoggedIn() {

  return (
    <Container>
      <Alert severity="error" sx={{m: 2}}>
        <AlertTitle>Error</AlertTitle>
        You need to be logged in to view this page — <Link to="/login">Go to login page.</Link>
      </Alert>
    </Container>
  )
}

export default App;
