import React, { useState } from 'react';
import { Typography, TextField, Box, Button } from '@mui/material';

import { Auth } from 'aws-amplify';

import { useFormik } from 'formik';

import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

interface FinishAccountSetupProperties {
    user: any
}

export default function FinishAccountSetupForm(props: FinishAccountSetupProperties) {

    let navigate = useNavigate();

    async function completeSetup(newPassword: string, phoneNumber: string) {

        console.log(props.user)

        Auth.completeNewPassword
        (
            props.user, 
            newPassword,
            {
                phone_number: phoneNumber
            }
        )
            .then(() => {
            navigate('/')
        }).catch((error) => {
            console.log(error)
        });
    }

    const newPasswordForm = useFormik({
        initialValues: {
            new_password: '',
            phone_number: ''
        },
        validationSchema: Yup.object({
            new_password: Yup.string()
                .required('Required'),
            phone_number: Yup.string()
                .required().matches(phoneRegExp)
        }),
        onSubmit: (values, { setSubmitting }) => {
            completeSetup(
                values['new_password'],
                values['phone_number']
            );
        }
    })

    return (
        <React.Fragment>
            <Typography component="h1" variant="h4" align="center" marginBottom={2}>
                Complete Account Setup
            </Typography>
            <Box
                sx={{
                    '& > :not(style)': { my: 1 },
                }}>
                <form onSubmit={newPasswordForm.handleSubmit}>
                    <TextField
                        sx={{mb:2}} 
                        id="new_password"
                        label="New Password"
                        variant="outlined"
                        type="password"
                        fullWidth={true}
                        value={newPasswordForm.values.new_password}
                        onChange={newPasswordForm.handleChange}
                        error={newPasswordForm.touched.new_password && Boolean(newPasswordForm.errors.new_password)}
                        helperText={newPasswordForm.touched.new_password && newPasswordForm.errors.new_password}
                    />
                    <TextField
                        id="phone_number"
                        label="Phone Number"
                        variant="outlined"
                        type="tel"
                        fullWidth={true}
                        value={newPasswordForm.values.phone_number}
                        onChange={newPasswordForm.handleChange}
                        error={newPasswordForm.touched.phone_number && Boolean(newPasswordForm.errors.phone_number)}
                        helperText={newPasswordForm.touched.phone_number && newPasswordForm.errors.phone_number}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            type="submit"
                            size="large"
                            variant="contained"
                            sx={{ mt: 2, ml: 1 }}
                        >
                            Submit
                        </Button>
                    </Box>
                </form>
            </Box>
        </React.Fragment>

    );
}